@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css");

html,
.dashboard {
	background-color: #F2F2F2;
}

/* Remove header bottom border */
.dashboard .container-fluid.border-bottom.d-none.d-xl-block {
	border-bottom: 0 !important;
}

.dashboard ul {
	padding: 0;
	margin: 0;
	list-style: none;
}

.header-container {
	position: fixed;
	top: 0;
	z-index: 500;
	width: 100%;
	/*background-color: var(--k-light);*/
}

.logo-container {
	width: 230px;
	min-width: 230px;
}

.dashboard-wrapper {
	margin-left: 260px;
	margin-right: 30px;
	margin-top: 60px;
	width: auto;
}

.dashboard-users {
	margin-left: 260px;
	margin-right: 30px;
	margin-top: 4150px;
	width: auto;
}

.sidebar {
	background-color: var(--k-primary);
	height: 100vh;
	width: 230px;
	min-width: 230px;
	font-size: 14px;
	position: fixed;
	z-index: 100;
	overflow: auto
}

.sidebar ul {
	padding: 0;
}

.sidebar ul li {
	list-style: none;
	display: flex;
}

.sidebar ul li a {
	color: #fff;
	text-decoration: none;
}

.sidebar-item {
	height: 46px;
}

.sidebar-item-selected {
	background-color: var(--k-primary-highlight);
}

.sidebar-item-selected::before {
	content: "";
	height: 100%;
	width: 2px;
	background-color: var(--k-primary);
	position: absolute;
	left: 0;
}

.sidebar-label {
	opacity: 0.5;
	font-weight: 100;
	text-transform: uppercase;
	font-size: 12px;
}

.top-menu {
	display: flex;
}

.top-menu .menu-left ul {
	display: flex;
	list-style: none;
	padding: 0;
}

.top-menu .menu-left ul li {
	margin-right: 30px;
}

.top-menu .menu-left {
	flex: 7;
}

.info-box {
	height: 180px;
	width: 410px;
	margin-left: 20px;
	border-radius: 10px;
}

.info-box:first-child {
	margin-left: 0;
}

.info-box-count {
	font-size: var(--k-text-xxl);
	font-weight: var(--k-font-weight-bold);
}

.info-box-title {}

.info-box-icon {
	width: 90px;
	max-width: 90px;
	height: auto;
	z-index: 0;
	right: 1.5rem !important;
}

.info-box-content {
	z-index: 1;
}

.box-1 {
	background: rgb(167, 146, 91);
	background: linear-gradient(90deg, rgba(167, 146, 91, 1) 0%, rgba(140, 122, 75, 1) 100%);
}

.box-2 {
	background: rgb(66, 69, 66);
	background: linear-gradient(90deg, rgba(66, 69, 66, 1) 0%, rgba(36, 38, 36, 1) 100%);
}

.box-3 {
	background: rgb(111, 179, 166);
	background: linear-gradient(90deg, rgba(111, 179, 166, 1) 0%, rgba(95, 158, 146, 1) 100%);
}

.border-radius {
	border-radius: 10px;
}

.icon-bg {
	background-color: var(--k-lightgrey);
	width: 40px;
	height: 40px;
	border-radius: 100px;
}

.icon-close {
	width: 25px;
	height: 25px;
	transition: transform .2s ease-in-out;
}

.bi.bi-x {
	line-height: 0;
}


.icon-close:hover {
	background-color: var(--k-lightgrey);
	color: red;
	border-radius: 100px;
	width: 25px;
	height: 25px;
}

.list-item:hover {
	background-color: var(--k-ultra-lightgrey);
}

.list-item-venue-img {
	width: 160px;
	height: 120px;
	object-fit: cover;
	border-radius: 10px;
}

.image-drop-container {
	content: " ";
	background: rgb(167, 146, 91);
	background: linear-gradient(0deg, rgba(167, 146, 91, 0.1) 0%,
			rgba(255, 255, 255, 1) 100%);
	min-height: 240px;
	width: 100%;
	border-radius: 10px;
	overflow: hidden;
	position: relative;
	text-align: center;
	padding: 10px;
	margin-bottom: 20px;
	border: 2px dashed #A7925B;
}

.image-drop-container:before {
	position: absolute;
	border: 3px dashed #A7925B;
	top: -2px;
	bottom: -2px;
	left: -2px;
	right: -2px;
	border-radius: 12px;
}

@keyframes wobble {
	0% {
		transform: translateY(0);
	}

	15% {
		transform: translateY(-3px);
	}

	30% {
		transform: translateY(2px);
	}

	45% {
		transform: translateY(-2px);
	}

	60% {
		transform: translateY(1px);
	}

	75% {
		transform: translateY(-1px);
	}

	100% {
		transform: translateY(0);
	}
}

.wobble {
	animation: wobble 0.8s ease-in-out;
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

.file-selector {
	display: block;
	margin: 20px 0;
}

.drop-area {
	border: 2px dashed #ccc;
	width: 200px;
	height: 200px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 18px;
	color: #ccc;
	margin: 20px 0;
}

.drop-area img {
	max-width: 100%;
	max-height: 100%;
}

.selected-img {
	width: 30px;
	height: 30px;
	background-color: #fff;
	position: absolute;
	top: 5px;
	right: 5px;
	border-radius: 100px;
}

.delete-img {
	opacity: 0.85;
	padding-top: 5px;
	padding-bottom: 5px;
	bottom: 0;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
}

.k-badge {
	background-color: var(--k-ultra-lightgrey);
}

.testx {
	margin-left: 260px;
	margin-right: 30px;
	margin-top: 3500px;
	width: auto;
}

.fade-in {
	animation: fadeIn 0.5s;
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

/* Checkmark */
.checkmark {
	display: inline-block;
	width: 22px;
	height: 22px;
	-ms-transform: rotate(45deg);
	/* IE 9 */
	-webkit-transform: rotate(45deg);
	/* Chrome, Safari, Opera */
	transform: rotate(45deg);
}

.checkmark_stem {
	position: absolute;
	width: 2px;
	height: 9px;
	background-color: var(--k-primary);
	left: 11px;
	top: 6px;
}

.checkmark_kick {
	position: absolute;
	width: 3px;
	height: 2px;
	background-color: var(--k-primary);
	left: 8px;
	top: 13px;
}

.choices-header {
	border-top-right-radius: 5px;
	border-top-left-radius: 5px
}

.shadow-soft {
	box-shadow: 1px 1px 14px 0px rgba(0, 0, 0, 0.04);
	-webkit-box-shadow: 1px 1px 14px 0px rgba(0, 0, 0, 0.04);
	-moz-box-shadow: 1px 1px 14px 0px rgba(0, 0, 0, 0.04);
}

.hide-items {
	display: none;
}


/* ===== Scrollbar CSS ===== */
/* Firefox */
.container-scrollbar {
	scrollbar-width: auto;
}

/* Chrome, Edge, and Safari */
.container-scrollbar::-webkit-scrollbar {
	width: 16px;
}

.container-scrollbar::-webkit-scrollbar-track {}

.container-scrollbar::-webkit-scrollbar-thumb {
	background-color: #C1C1C1;
	border-radius: 10px;
	border: 3px solid #ffffff;
}

a {
	text-decoration: none !important;
}

.add-button {
	right: 30px;
	top: 30px
}

/* Dropdown button */

.k-dropdown-button .dropdown-button {
	background-color: var(--k-primary) !important;
}

.k-dropdown-button .dropdown-menu.show {
	border-radius: 15px;
	margin-top: 5px;
	font-size: 0.9rem;
	padding: 10px;
}

.k-dropdown-button .dropdown-item {
	border-radius: 10px;
	padding-top: 6px;
	padding-bottom: 6px;
}

.dropdown-item:hover,
.dropdown-item:focus {
	background-color: var(--k-lightgrey);
}

.k-dropdown-button .dropdown-item:active {
	background-color: var(--k-lightgrey);
	color: var(--k-primary);
}

/* ToggleSwitch */

.switch-checkbox {
	height: 0;
	width: 0;
	visibility: hidden;
}
  
.switch-label {
	display: flex;
	align-items: center;
	cursor: pointer;
	width: 50px;
	height: 25px;
	background: rgb(0, 0, 0);
	border-radius: 100px;
	position: relative;
}
  
.switch-label .switch-button {
	content: '';
	position: absolute;
	bottom: 2px;
	left: 2px;
	width: 21px;
	height: 21px;
	border-radius: 45px;
	transition: .08s;
	background: #fff;
	box-shadow: 0 0 8px 0 rgba(10, 10, 10, 0.29);
}
  
.switch-checkbox:checked + .switch-label .switch-button {
	left: calc(96%);
	transform: translate(-100%);
	background-color: var(--k-success);
}

button.stats-venue-toggle {
    --bs-btn-bg: unset;
    --bs-btn-color: unset;
    --bs-btn-border-color: unset;
    --bs-btn-hover-bg: unset;
    --bs-btn-hover-color: unset;
    --bs-btn-hover-border-color: unset;
    --bs-btn-active-border-color: unset;
    --bs-btn-active-bg: unset;
}
