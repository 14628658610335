* {
	box-sizing: border-box;
}

body {
	font-family: var(--k-font);
	font-weight: var(--k-font-weight-med);
}

html {
	font-size: 14px;
}

:root {
	/* Theme */
	--k-primary: #242624;
	--k-primary-highlight: #393C39;
	--k-primary-light: #A7925B;
	--k-primary-ulight: #F6F4EF;
	--k-secondary: #EDF2F3;
	--k-success: #2AC77E;
	--k-danger: #ED5664;
	--k-warning: #E5BF4C;
	--k-info: #96C5CF;
	--theme-blue-saffire: #2D5F73;
	--k-background-blue: #8EA1A6;
	--k-background-light-grey: #FAFCFC;
	--k-background-lightblue: #EFF4F6;
	--k-background-dark: #23262F;
	--k-background-dark-highlight: #383C49;
	--k-background-torquise: #204150;
	--k-lightgrey: #EDF2F3;
	--k-ultra-lightgrey: #F7F7F7;
	--k-light: #FFF;
	--k-text-grey: #5E7175;
	--k-text-lightgrey: #B1B5C3;
	--k-text-light: #FFF;
	--k-text-filterorange: #E4683A;
	--k-dark: #23262F;
	--k-border-grey: #E6E8EC;
	--k-border-lightgrey: #EDF2F3;
	--k-input-lightblue: #F2F6F7;
	--k-transparent: transparent;
	--k-font: "Bitter", serif;
	--k-font-title: "Raleway", sans-serif;

	/* Structure */
	--k-border-0: 0;
	--k-border-1: 1px solid;
	--k-border-2: 2px solid;
	--k-border-rounded: 500px;
	--k-border-radius-md: 24px;
	--k-text-xxl: 34px;
	--k-text-xl: 20px;
	--k-text-lg: 16px;
	--k-text-md: 14px;
	--k-text-sm: 12px;
	--k-font-weight-regular: 300;
	--k-font-weight-med: 400;
	--k-font-weight-semi: 500;
	--k-font-weight-bold: 600;
	--k-font-weight-bolder: 700;
	--k-font-lineheight-24: 1.714;
	--k-font-lineheight-25: 1.563;

	/* Bootstrap overwrites */
	--bs-body-color: var(--k-text-dark);
	--bs-nav-link-font-size: 14px;
}

/* Bootstrap overwrites */

.font-primary {
	font-family: var(--k-font-title);
}

.font-secondary {
	font-family: var(--k-font);
	color: red !important;
	font-size: 50px !important;
	background-color: red !important;
}

a:hover {
	color: var(--k-primary-light);
}

input[type="kform"],
textarea {
	background-color: var(--k-input-lightblue);
	border: 0px;
	outline-color: transparent;
	border-radius: 6px;
}

.modal-content {
	-webkit-border-radius: var(--k-border-radius-md) !important;
	-moz-border-radius: var(--k-border-radius-md) !important;
	border-radius: var(--k-border-radius-md) !important;
}

.checkbox_container {
	margin-bottom: 5px;
}

.border-grey {
	border-color: var(--k-border-lightgrey) !important;
}

.lead {
	font-weight: inherit;
	font-family: var(--k-font-title);
}

/* Bootstrap overwrites end */

h1,
h2,
h3,
h4,
h5,
h6,
.display-1,
.display-2,
.display-3,
.display-4 {
	font-weight: var(--k-font-weight-bolder) !important;
	font-family: var(--k-font-title);
	color: var(--k-primary);
}


.hero-title {
	width: 65%;
}

.hero-tagline {
	width: 52%;
}

.hero-link:hover {
	color: #fff;
}

.hero-link {
	font-size: var(--k-text-md);
}

.display-5 {
	line-height: 1.3;
}

.text-sm {
	font-size: var(--k-text-sm);
}

.text-md {
	font-size: var(--k-text-md);
}

.text-lg {
	font-size: var(--k-text-lg);
}

.text-weight-reg {
	font-weight: var(--k-font-weight-med);
}

.text-weight-med {
	font-weight: var(--k-font-weight-med);
}

.text-weight-semi {
	font-weight: var(--k-font-weight-semi);
}

.text-bold {
	font-weight: var(--k-font-weight-bold);
}

.text-xxl {
	font-size: var(--k-text-xxl);
}

.text-xl {
	font-size: var(--k-text-xl);
}

.text-lineheight-24 {
	line-height: var(--k-font-lineheight-24);
}

.text-lineheight-25 {
	line-height: var(--k-font-lineheight-25);
}

.primarycolor {
	color: var(--k-primary);
}

.bg-ulight {
	background-color: var(--k-primary-ulight);
}

.primarycolor-light {
	color: var(--k-primary-light);
}

.bg-primary-light {
	background-color: var(--k-primary-light);
}

.bg-light-grey {
	background-color: var(--k-background-light-grey);
}

.red_text {
	color: var(--k-danger);
}

.green_text {
	color: var(--k-success);
}

.rounderborder {
	border-radius: var(--k-border-radius-md);
}

.pagination-round {
	border-radius: var(--k-border-rounded);
	border: 2px solid;
	border-color: var(--k-border-grey);
}

.k-input {
	border-radius: 10px;
	border-color: var(--k-lightgrey);
}

.k-btn {
	border-radius: var(--k-border-rounded);
	border: var(--k-border-2);
	font-weight: var(--k-font-weight-bold);
	font-family: var(--k-font-title);
	padding-left: 20px;
	padding-right: 20px;

}

.promoted-cards-address {
	color: var(--k-text-grey)
}

.k-btn:disabled {
	border: 1px solid var(--k-primary-light);
	background-color: #cfcfcf;
	border: 0;
}

.k-btn-grey {
	color: var(--k-primary);
	border-color: var(--k-lightgrey);
	background-color: var(--k-lightgrey);
}

.k-btn-large {
	height: 48px;
}

.k-btn-danger {
	color: var(--k-danger);
	border-color: var(--k-danger);
	background-color: var(--k-text-light);
}

.k-btn-greybg {
	background-color: var(--k-input-lightblue);
	border-radius: var(--k-border-rounded);
	border: var(--k-border-2);
	border-color: var(--k-input-lightblue);
	font-size: var(--k-text-md);
}

.k-btn-round {
	padding-inline: 12px;
	padding-block: 10px;
	border-radius: 100px;
}

.k-btn-prim {
	background: var(--k-primary);
	border: var(--k-border-3);
	color: var(--k-text-light);
	border-color: white;
}

.k-btn-white {
	border-color: white;
	color: white;
	background-color: transparent;
}

.k-btn-map {
	color: var(--k-primary);
	background-color: white;
	border-color: var(--k-secondary);
	border-radius: 20px;
}

.k-btn-blue {
	color: white;
	background-color: #34ABE1;
	border-color: #34ABE1;
}

.k-btn-prim:hover {
	background: var(--k-primary-highlight);
	border-color: var(--k-primary-highlight)
}

.icon-arrow {
	position: absolute;
	left: 46%;
}

.k-btn-blank {
	background-color: transparent;
	border-width: 0;
	-webkit-box-shadow: 0 17px 32px -16px rgba(0, 0, 0, 0.214);
	-moz-box-shadow: 0 17px 32px -16px rgba(0, 0, 0, 0.214);
	box-shadow: 0 17px 32px -16px rgba(0, 0, 0, 0.214);
}

.k-btn-sec {
	background: var(--k-primary-light);
	border-color: var(--k-primary-light);
	color: white;
}

.k-btn-light {
	background-color: (--k-secondary);
}

.icon-only-btn {
	width: 40px !important;
	height: 40px !important;
	border-radius: 100px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 2px solid var(--k-lightgrey);
	background-color: var(--k-lightgrey);
}

.icon-only-btn-transparent {
	border: 2px solid #fff;
	background-color: transparent;
}

.search_bg_transparent {
	color: var(--text_dark_grey);
	background: url("https://static.optimeet.dk/gfx/ico_search-0.svg") no-repeat 16px 16px;
	background-position: 10px;
	background-color: #e7edf2;
	border: 1px solid #e7edf2;
	padding-left: 35px;
 	height: 53px;
}

.search_bg_transparent::placeholder {
	color: var(--text_dark_grey);
}

.border_radius_24 {
	border-radius: 24px;
}

.mobile_map_buttons {
	background-color: transparent;
	width: 100%;
	height: 40px;
	top: 0;
	position: absolute;
	z-index: 999;
}

.filter-container {}

.listing-map-container {
	position: absolute;
	z-index: -1000;
	top: 0;
	width: 100%;
}

.listingmap {
	height: 100vh !important;
}

@media only screen and (max-width: 767px) {
	.listingmap {
		width: 100%;
		height: 479px;
	}
}

@media (min-width: 768px) {
	.listingmap {
		height: 879px;
	}

	.mobile-text {
		color: #fff !important;
	}
}

.regionmap {
	height: 740px !important;
}

@media only screen and (max-width: 767px) {
	.venuemap {
		width: 100%;
		height: 300px;
		border-radius: 24px;
	}

	.contact-form-mobile {
		border: 2px solid rgba(0, 0, 0, 0.08);
		box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
	}
}

@media (min-width: 768px) {
	.venuemap {
		width: 500px;
		height: 400px;
		border-radius: 24px;
	}
}

.previewmap {
	width: 100%;
	height: 400px;
	border-radius: 24px;
}

.centerComponent {
	display: flex;
	flex-Flow: row nowrap;
	justify-Content: center;
	align-Items: center;
}

.dropdown-toggle {
	font-size: var(--k-text-md);
	color: var(--k-text-grey);
	border: var(--k-border-0);
	background: var(--k-transparent);
}

.dropdown-toggle:hover,
.dropdown-toggle:active,
.dropdown-toggle:first-child:active,
.dropdown-toggle:focus-visible {
	background-color: var(--k-transparent);
	color: var(--k-text-grey);
	border: var(--k-border-0);
}

.dropdownlist {
	background-color: var(--k-input-lightblue);
	border-radius: 10px;
}

.dropdownlist-inner,
.dropdownlist-inner:hover,
.dropdownlist-inner:active,
.dropdownlist-inner:first-child:active,
.dropdownlist-inner:focus-visible {
	z-index: 99;
	background-color: var(--k-input-lightblue);
	color: grey;
	border: 0;
}

.dropdownlist-calendar {
	background-color: #fff;
	color: #23262F;
	border: 0;
	border-radius: 0.375rem;
	height: 45px;
	display: flex;
	justify-content: space-between;
	background-repeat: no-repeat;
	background-position-x: 364px;
	background-position-y: 21px;
}

.dropdownlist-calendarinner {
	color: grey;
	background: url("https://static.optimeet.dk/gfx/ico_calendar-0.svg") no-repeat 16px 16px;
	background-position: 17px;
	background-position-y: 13px;
	width: 100%;
	border: 0px;
	padding-left: 42px;
	height: 47px;
	font-size: var(--k-text-md);
	font-weight: var(--k-font-weight-semi);
}

.category {
	border: var(--k-border-2);
	border-color: var(--k-border-lightgrey);
	border-radius: var(--k-border-radius-md);
	height: 180px;
	min-width: 200px;
	transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
	overflow: hidden;
}

.category_landing {
    border: var(--k-border-2);
    border-color: var(--k-border-lightgrey);
    border-radius: var(--k-border-radius-md);
    height: 125px;
    width: auto;
	min-width: 190px;
    max-width: 100%;
    max-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.hover-effect:hover {
	transform: translateY(-5px);
	box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
	border-color: var(--k-border-darkgrey);
}

.greyoutline {
	border: var(--k-border-2);
	border-color: var(--k-border-lightgrey);
	border-radius: var(--k-border-radius-md);
}

.underline {
	border-bottom: 1px solid var(--k-lightgrey);
}

.label {
	background-color: var(--k-ultra-lightgrey);
	display: inline-block;
	border-radius: var(--k-border-rounded);
}

.vertline {
	border-left: 1px solid var(--k-border-grey);
	height: 40px;
}

.transparent-desktop {
	background: transparent;
}

.text-dark {
	color: var(--k-text-dark);
}

.headertext {
	color: var(--k-text-dark);
	font-weight: var(--k-font-weight-bold);
	font-size: var(--k-text-lg);
}

.header .nav-icon-headphones img {
	margin-right: 8px;
}

.header .partner-nav-container .nav-link {
	padding-right: 0;
}

.navbar-toggler,
.navbar-toggler:hover,
.navbar-toggler:focus,
.navbar-toggler:visited {
	border: 0;
	box-shadow: none;
}

.navbar li a {
	font-family: var(--k-font-title);
}

/* Transparent Header */
.transparent-header {
	position: absolute;
	top: 24;
	width: 100%;
}

.transparent-header .collapse.navbar-collapse {
	/* margin-top: 20px; */
}

.collapsing {
	-webkit-transition: none !important;
	transition: none !important;
	display: none;
	transition-delay: 0s !important;
}

.transparent-header .header {
	border-bottom: 0 !important;
}

.transparent-header a {
	color: #fff;
}

.transparent-header a:hover {
	color: #fff;
}

.transparent-header .k-btn {}

.transparent-header .btn-title {
	color: #fff;
}

@media only screen and (min-width: 1200px) {
	.venuemap {
		width: 600px;
		height: 500px;
		border-radius: 24px;
	}
}

@media only screen and (max-width: 1200px) {
	.locations_container {
		max-width: 970px;
		margin: 0 auto;
	}


	.inspire-img {
		max-width: 400px;
	}


	.icon-arrow {
		display: none;
	}

	.magazine_img {
		width: 170px;
		height: 247px;
	}

	.header .nav-icon-headphones .btn-title {
		display: none;
	}

	.header .nav-icon-headphones img {
		margin-right: 0;
	}

	.header .nav-icon-headphones .k-btn {
		padding: 0 !important;
		margin: 0 !important;
		display: block;
		width: 40px;
		height: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.gallery_mobile_img {
		width: 912px !important;
	}
}

@media only screen and (max-width: 1270px) {
	.dollars-container {
		position: absolute;
		top: 120px;
		right: 0;
	}
}

@media only screen and (max-width: 992px) {
	.header .collapse.navbar-collapse {
		margin-top: 0;
	}

	.hero-title {
		width: 75%;
	}

	.transparent-desktop {
		background: var(--k-lightgrey) !important;
	}

	.magazine_img {
		width: 222px;
		height: auto;
	}

	.venuemap {
		width: 100%;
	}

	.hero_bg_img {
		margin-top: -160px;
		padding-top: 150px !important;
	}

	.header:has(.show) {
		background-color: #fff !important;
	}

	.transparent-header a {
		color: inherit;
	}

	.transparent-header,
	.header {
		position: relative;
	}

	.transparent-header a {
		color: inherit !important;
	}

	.transparent-header .btn-title {
		color: inherit;

	}

	.transparent-header .nav-item img {
		filter: inherit;
	}

	.header .menu-buttons {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.header .menu-buttons li {
		margin-right: 10px;
	}

	.headertext {
		border-bottom: 1px solid var(--k-border-lightgrey);
	}

	.navbar-toggler {
		background-color: #fff;
		padding: 10px !important;
		border-radius: 100px;
		height: 45px;
		width: 95px;
		justify-content: flex-end;
		display: flex;
		padding-right: 15px !important;
	}

	.navbar-toggler-icon {
		width: 25px !important;
		height: 25px !important;
	}

	.navbar-toggler-icon::before {
		content: "MENU";
		font-size: 12px;
		margin-left: -70px;
	}

	.gallery_mobile_img {
		width: 672px !important;
	}

	.hero-tagline {
		width: 100%;
	}

}

@media only screen and (max-width: 767px) {
	.backgroundBoxBlue {
		background-color: var(--k-background-lightblue);
	}

	.magazine_img {
		width: 320px;
		height: auto;
	}

}

@media (min-width: 768px) {
	.diplay-5 {
		font-size: 3.1rem;
	}
}

@media (min-width: 768px) {
	.backgroundBoxBlue {
		border-radius: 30px;
		background-color: var(--k-background-blue);

	}
}

.popularbooking_bg {
	background-color: var(--k-border-lightgrey);
	border-radius: 10px;
}

.gethelp_img {
	object-fit: cover;
	position: relative;
}

.iconButton {
	width: 40px;
	height: 40px;
	border-color: var(--k-lightgrey);
}

.grey {
	color: var(--k-text-grey);
}

.greybg {
	background-color: var(--k-border-grey);
}

.lightgrey {
	color: var(--k-text-lightgrey);
}

.white {
	color: var(--k-light);
}

.filterorange {
	color: var(--k-text-filterorange);
}

.onelinetext {
	overflow: hidden;
	white-space: nowrap;
}

.centerItem {
	display: flex;
	align-items: center;
	justify-content: center;
}

.opposites {
	display: flex;
	justify-content: space-between;
}

@media (max-width: 767px) {
	.w100_mobile {
		width: 100%;
	}

	.hero-title {
		width: 100%;
	}
}

.link_nostyle {
	color: inherit;
}

.link_nostyle:hover {
	text-decoration: inherit;
}

.overflow_hidden {
	overflow: hidden;
}

.venue_rightpanel_scale {
	border-radius: 10px;
	border: 1px solid var(--k-border-grey);
}

.venue-card-desc-container {
	height: 70px;
}

.venue-card-desc {
	max-height: 65px;
	overflow: hidden;
	font-weight: var(--k-font-weight-med);
}

.headersearchcard{
	width: 400px;
}

.headersearchcard:hover {
	background-color: #8ea1a63a;
	transition-delay: 100ms;
}

@media only screen and (max-width: 767px) {
	.absolutexl {
		position: relative;
	}
}

@media (min-width: 768px) {
	.absolutexl {
		position: absolute;
	}
	.headersearchtop {
		top: 65px;
	}
}

.gallery_modal {
	width: 1200px;
}

.gallery-thumbnails-wrapper {
	/*background-color: rgb(0, 0, 0, 0.7);
	margin-top: -170px;*/
	margin-top: -150px;
}

.gallery_mobile_img_wrapper {
	width: 100%;
	min-height: 400px;
	max-height: 400px;
}

.gallery_mobile_img {
	width: 100%;
	min-height: 400px;
	max-height: 400px;
	object-fit: cover;
	border-radius: 20px;
}

.mobile_gallery_buttons_wrapper {
	position: absolute;
	width: 100%;
	top: 45%;
	display: flex;
	justify-content: space-between;
	flex-direction: row-reverse;
}

.mobile_gallery_lbtn {
	background-color: var(--k-primary);
	border-radius: 20px;
	height: 36px;
	width: 36px;
	padding: 12px;
	margin-left: -15px;
}

.mobile_gallery_rbtn {
	background-color: var(--k-primary);
	border-radius: 20px;
	height: 36px;
	width: 36px;
	padding: 12px;
	margin-right: -15px;
}

.mobile_buttons_wrapper {
	position: absolute;
	top: 45%;
	display: flex;
	justify-content: space-between;
	width: 100%;
	flex-direction: row-reverse;
}

.mobile_right_icon {
	margin-right: -40px;
	transform: rotate(180deg);
	width: 30px
}

.mobile_left_icon {
	margin-left: -40px;
	width: 30px;
}

.gallery_thumb_large {
	width: 638px;
	height: 451px;
	border-radius: 24px;
	object-fit: cover;
}

.gallery_scroll {
	display: flex;
	flex-direction: row;
	overflow-x: scroll;
	position: relative;
	top: -20px
}

.thumb_large_btn_position {
	position: absolute;
	bottom: 15px;
	right: 15px;
}

.gallery_thumb_small_wrapper {
	flex: 1;
	border-radius: 20px;
	overflow: hidden;
	margin-left: 10px;
	max-height: 221.5px;
}

.gallery_wrapper .modal-content {
	background-color: transparent;
	border: 0;
}

.gallery_container,
.gallery_container_inner,
.gallery_container_section {
	height: 100%;
}

.gallery_thumb_small {
	width: 100%;
	height: 100%;
	object-fit: cover;
	display: block;
}

.gallery_img {
	border-radius: 10px;
}

.gallery_img_selected {
	border-color: var(--k-primary-light);
	border-width: 3px;
	border-style: solid;
}

.btn_radio {
	border: 0;
	background-color: initial;
	font-weight: var(--k-font-weight-bold);
	cursor: pointer;
	transition: box-shadow 0.2s;
	padding: 15px;
	padding-right: 15px;
	text-align: start;
}

.btn_radio:disabled {
	color: initial;
	box-shadow: 0 1px 0 var(--k-primary);
}

.konference_footer {
	background-color: var(--k-primary);
	min-height: 100vh;
	min-height: 100%;
	padding: 0;
}

.input-light-blue {
	background-color: var(--k-input-lightblue);
	border: 0;
}

.input-light-blue:focus {
	background-color: var(--k-input-lightblue);
}

.ql-container.ql-snow {
	background-color: var(--k-input-lightblue);
	border-bottom-left-radius: 0.375rem;
	border-bottom-right-radius: 0.375rem;
}

.ql-toolbar.ql-snow {
	border-top-left-radius: 0.375rem;
	border-top-right-radius: 0.375rem;
}

scrolling-wrapper-flexbox {}

@media only screen and (max-width: 1740px) {
	.gallery_modal {
		width: 100% !important;
	}
}

@media only screen and (min-width: 1400px) {
	.venuemap {
		width: 700px;
	}

	.magazine_img {
		width: 297px;
		height: 370px;
	}


}

@media only screen and (max-width: 1400px) {
	.gallery_thumb_large {
		width: 538px;
		height: 350px;
	}

	.gallery_thumb_small_wrapper {
		max-height: 171px;
	}

	.icon-arrow {
		left: 42%;
	}
}

@media only screen and (max-width: 767px) {
	.copyrighttext {
		font-size: var(--k-text-sm);
		font-weight: 500;
		font-size: var(--k-text-sm);
	}
}

.location-selector {
	border-radius: 100px;
}


@media only screen and (max-width: 767px) {

	.hero_bg_img {
		/* use this when removing notifcation: margin-top: -100px */
		margin-top: -80px;
	}

	.checkbox_col {
		width: 50px;
	}

	.location-selector {
		border-radius: 10px;
		padding: 30px !important
	}

	.location-selector-item {
		padding: 10px;
		padding-left: 0;
		padding-right: 0;
		border-bottom: 1px solid var(--k-border-grey);
		border-radius: 0;
	}

	.location-selector-item input,
	.location-selector-item button {
		height: 30px;
	}

}

@media (min-width: 768px) {
	.checkbox_col {
		float: left;
		width: 30%;
	}
}

hr {
	padding: 0px 0px 0px 0px;
	display: block;
	position: absolute;
	right: 0;
	width: 100%;
	border: 1px solid var(--k-text-lightgrey);
}

.scrolling-wrapper-flexbox::-webkit-scrollbar {
	-ms-overflow-style: none;
	scrollbar-width: none;
	display: none;
}



.newsletter .form-control,
.newsletter .form-control:focus {
	background-color: #fff;
}

@media only screen and (max-width: 767px) {
	scrolling-wrapper-flexbox . {
		display: flex;
		flex-wrap: nowrap;
		overflow-x: auto;
		-ms-overflow-style: scrollbar;
		scrollbar-width: thin;
	}

	.scrolling-wrapper,
	.scrolling-wrapper-flexbox {
		height: auto;
		-webkit-overflow-scrolling: touch;
	}

	.scrolling-wrapper::-webkit-scrollbar,
	.scrolling-wrapper-flexbox::-webkit-scrollbar {
		display: none;
	}
}

@media (max-width: 768px) {
	.gallery_mobile_img {
		width: 492px !important;
	}
}

@media (max-width: 542px) {
	.gallery_mobile_img {
		width: 100% !important;
	}
}


@media (min-width: 768px) {}

@media (max-width: 767px) {
	.mobile-text {
		text-align: left;
		color: var(-k--primary);
	}
}

.scroll-smooth {
	scroll-behavior: smooth;
}

.hero_bg_img {
	background-size: cover;
	background-image: url("https://static.optimeet.dk/imgs/hero_1920x800-3.webp");
	background-image: -webkit-image-set(url("https://static.optimeet.dk/imgs/hero_1920x800-3.webp") 1x,
			url("https://static.optimeet.dk/imgs/hero_3840x1600-2.webp") 2x);
	background-image: image-set(url("https://static.optimeet.dk/imgs/hero_1920x800-3.webp") 1x,
			url("https://static.optimeet.dk/imgs/hero_3840x1600-2.webp") 2x);
	background-position: top;
}

.herolisting_bg_img {
	background-size: cover;
	background-image: url("https://static-konference-dk.s3.eu-north-1.amazonaws.com/imgs/herolisting_1920x300px-0.webp");
	background-image: -webkit-image-set(url("https://static-konference-dk.s3.eu-north-1.amazonaws.com/imgs/herolisting_1920x300px-0.webp") 1x,
			url("https://static-konference-dk.s3.eu-north-1.amazonaws.com/imgs/herolisting_3840x600px-0.webp") 2x);
	background-image: image-set(url("https://static-konference-dk.s3.eu-north-1.amazonaws.com/imgs/herolisting_1920x300px-0.webp") 1x,
			url("https://static-konference-dk.s3.eu-north-1.amazonaws.com/imgs/herolisting_3840x600px-0.webp") 2x);
	background-position: top;
}

.herolisting_bg_img {
	height: 250px;
}


@media only screen and (max-width: 576px) {
	.hero_bg_img {
		background-size: cover;
		background-image: url("https://static.optimeet.dk/imgs/hero_mobile_485x920-2.webp");
		background-image: -webkit-image-set(url("https://static.optimeet.dk/hero_mobile_485x920-2.webp") 1x,
				url("https://static.optimeet.dk/imgs/hero_mobile_970x1840-2.webp") 2x);
		background-image: image-set(url("https://static.optimeet.dk/imgs/hero_mobile_485x920-2.webp") 1x,
				url("https://static.optimeet.dk/imgs/hero_mobile_970x1840-2	.webp") 2x);
		background-position: top;
	}

	.full-mobile {
		width: 100% !important;
	}

	.scrolling-wrapper-flexbox {
		display: flex;
		flex-wrap: nowrap;
		overflow-x: auto;
	}

	.scrolling-wrapper,
	.scrolling-wrapper-flexbox {
		height: auto;
		-webkit-overflow-scrolling: scroll;
	}
}

@media only screen and (min-width: 576px) {

	.scrolling-wrapper-flexbox {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
	}

	.category {
		margin-bottom: 15px;
	}
}

@media only screen and (max-width: 1590px) {
	.category-slider.scrolling-wrapper-flexbox {
		max-width: 990px !important;
	}
}

.map_bg_img {
	object-fit: cover;
	border-radius: 10px;
	width: 100%;
	height: 121px;
	background-image: url("https://static.optimeet.dk/imgs/mapthumb.webp");
}

.map_cluster {
	font-size: 16px;
	font-weight: 600;
	padding-top: 8px;
	background-color: black;
	border-radius: 20px;
	color: white;
	box-shadow: 0px 0px 0px 10px rgba(0, 0, 0, 0.18);
	transition: all 0.2s ease 0s;
}

.map_cluster:hover {
	background-color: var(--k-primary-light);
	color: #fff;
	transform: translateY(-7px);
	box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
}

.alert-primary {
	background-color: #F4EFE4;
	color: #A7925B;
	border-color: #E2D8BF;
}

.cursor_pointer {
	cursor: pointer;
}

.cursor_drag {
	cursor: grab;
}

/* Forms */
.form-control:focus {
	background-color: var(--k-input-lightblue);
}

.form-control {
	background-color: var(--k-input-lightblue);
	border: 0;
}

.whitebox {
	background-color: #fff;
	padding: 40px;
	border-radius: 10px;
	margin-top: -200px;
	position: relative;
	width: 80%;
	left: 10%;
}

a.remove-link-style,
a.remove-link-style:hover {
	text-decoration: none !important;
	color: inherit;
}

a {
	color: var(--k-primary-light);
}

.find-location {
	background-color: #F2F6F7;
	border-radius: var(--k-border-radius-md);
}

.find-location-text {
	height: fit-content;
	background-color: #fff;
	border-radius: var(--k-border-radius-md);
}

.fade-up {
	animation: move-up 1s ease-in-out forwards;
}

@keyframes move-up {
	from {
		top: 0;
		opacity: 0;
	}

	to {
		top: -20px;
		opacity: 1;
	}
}

.shadow-soft {
	box-shadow: 1px 1px 14px 0px rgba(0, 0, 0, 0.04);
	-webkit-box-shadow: 1px 1px 14px 0px rgba(0, 0, 0, 0.04);
	-moz-box-shadow: 1px 1px 14px 0px rgba(0, 0, 0, 0.04);
}

.leaflet-control-container {
	position: absolute;
	bottom: 84px
}

.modal-backdrop .show {
	opacity: 0.5
}

.alert-modal {
	z-index: 1060;
	position: fixed;
	max-width: 100%;
	min-width: 20%;
	right: 2rem;
	bottom: 2rem;
	transition: top 2.4s;
}

.alert-modal .alert {
	margin-bottom: 0 !important;
}

.alert-modal .alert.success {
	border-color: var(--k-success);
	background-color: var(--k-success);
}

.alert-modal .alert.error {
	border-color: var(--k-danger);
	background-color: var(--k-danger);
}

.alert-modal .alert.info {
	border-color: var(--k-primary-light);
	background-color: var(--k-primary-light);
	color: black;
}

.booking-box {
	background-color: var(--theme-blue-saffire);
}

.booking-box .dropdownlist-inner {
	background-color: #fff;
}

.pop-up-button:hover {
	animation: pulse 0.8s ease-out;
	background-color: var(--k-primary-light);
}


@keyframes pulse {
	0% {
		box-shadow: 0 0 0 0 rgba(36, 38, 36, 0.5);
	}

	100% {
		box-shadow: 0 0 0 15px rgba(36, 38, 36, 0);
	}
}


/* react quill editor */
.ql-editor p {
	margin-bottom: 1rem !important;
}

.accordion-content {
	max-height: 0;
	overflow: hidden;
	transition: max-height 0.3s ease-in-out;
}

.showAccordion {
	max-height: 1000px;
}

.chevron-icon {
	transition: transform 0.3s ease-in-out;
}

.flip {
	transform: rotate(180deg);
}

.team-img {
	filter: brightness(100%) contrast(100%) saturate(0%) blur(0px) hue-rotate(0deg);
}

.custom-tooltip .tooltip-inner {
	max-width: 100%;
	padding: 15px;
	background-color: var(--k-light);
	color: black;
	border: 2px;
	border-radius: 10px;
	border-style: solid;
	border: 0;
	transition: all 1.2s ease 1s;
	box-shadow: 1px 1px 14px 0px rgba(0, 0, 0, 0.04);
	-webkit-box-shadow: 1px 1px 14px 0px rgba(0, 0, 0, 0.04);
	-moz-box-shadow: 1px 1px 14px 0px rgba(0, 0, 0, 0.04);
}

.venue-3d-bg {
	background-image: url("https://static-konference-dk.s3.eu-north-1.amazonaws.com/gfx/3d_venue_bg_2x.webp");
	background-size: cover;
	height: 150px;
}

.hoverfx:hover {
	filter: brightness(85%);
	transition: transform 0.3s ease;
}

.hoverfxlight:hover {
	filter: brightness(97%);
	transition: transform 0.3s ease;
}

.hoverfxbright:hover {
	filter: brightness(107%);
	transition: transform 0.3s ease;
}