.privacy_page,
.privacy_page a,
.privacy_page p,
.privacy_page span {
	font-size: 13px;
}

.privacy_page b {
	font-weight: 600;
}

.privacy_page h1 {
	font-size: 190%;
	font-weight: 600;
}

.privacy_page h2 {
	font-size: 150%;
	font-weight: 600;
	line-height: 120%;
}

.privacy_page h3 {
	font-size: 120%;
	font-weight: 600;
	line-height: 120%;
}
