.react-datepicker__triangle {
	display: none;
}

.datePickerWrapper {
	/*position: absolute;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 1070px*/
}

.datePickerWrapper input {
	border: 0;
	padding: 0;
}

.form-control:focus {
	border: 0 !important;
	box-shadow: none !important;
}

.form-control {
	font-size: 14px !important;
	color: var(--k-text-grey) !important;
	margin-top: 3px;
}

.datePickerWrapper input::placeholder {
	color: var(--k-text-grey) !important;
}


.datePickerWrapper input::placeholder {
	font-size: 14px !important;
	overflow: visible;
}

.react-datepicker {
	padding: 30px;
	border: 1px solid var(--k-border-lightgrey);
	border-radius: 24px;
	box-shadow: 0px 40px 32px -24px rgb(15 15 15 / 12%)
}

.react-datepicker__header {
	background-color: #fff;
	border: none;
	font-family: var(--k-font);
	padding-top: 0;
}

.react-datepicker__navigation--next,
.react-datepicker__navigation--previous {
	top: inherit;
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range,
	.react-datepicker__month-text--in-range,
	.react-datepicker__quarter-text--in-range,
	.react-datepicker__year-text--in-range) {
	background-color: var(--k-primary);
}

.react-datepicker__day--in-range {
	border-radius: 0;
	background-color: var(--k-lightgrey);
	padding: 0;
	color: var(--k-text-dark);
}

.react-datepicker__day--in-selecting-range {
	background-color: var(--k-lightgrey) !important;
	color: black;
}

.react-datepicker__day {}

.react-datepicker__day:hover {
	border-radius: 100px;
}

.react-datepicker__day--selected {
	background-color: var(--k-primary) !important;
	border-radius: 100px !important;
	color: #fff;
}

.react-datepicker__day--range-start,
.react-datepicker__day--range-end {
	background-color: var(--k-primary);
	border-radius: 100px !important;
	position: relative;
	color: var(--k-text-light);
}

.react-datepicker__day--range-start::before {
	content: "";
	position: absolute;
	top: 0;
	bottom: 0;
	z-index: -1;
	width: 50%;
	background: red;
	right: 0;
}

.react-datepicker__day--in-selecting-range {
	border-radius: 0;
}

.location-selector-item {
	border-right: 1px solid;
}

.location-selector-item .form-control,
.location-selector-item .form-control:focus {
	background-color: #fff;
}

.dropdown-item {
	padding-top: 7px;
	padding-bottom: 7px;
}

/* Input text size */
.location-selector-item .form-control::placeholder,
.location-selector-item .form-control[value],
.datePickerWrapper .location-selector-item button {
	font-size: 16px !important;
	color: var(--k-text-grey) !important;
	font-weight: 400;
}

@media only screen and (max-width: 767px) {
	.arrowButton {
		width: 44px;
		height: 44px;
		background-color: var(--k-primary);
		position: absolute;
		border-radius: var(--k-border-rounded);
		right: 8px;
		bottom: 6px;
	}

	.location-selector-item {
		border-right: none;
	}
}

@media (min-width: 768px) {
	.arrowButton {
		width: 57px;
		height: 57px;
		background-color: var(--k-primary);
		position: absolute;
		border-radius: var(--k-border-rounded);
		top: 20px;
		right: -10px;
		transition: background-color .3s ease-out;
	}
	
	.location-selector-item {
		border-right: none;
	}
}

@media (min-width: 980px) {
	.arrowButton {
		width: 74px;
		height: 74px;
		background-color: var(--k-primary);
		position: absolute;
		border-radius: var(--k-border-rounded);
		top: 15px;
		right: 15px;
		transition: background-color .3s ease-out;
	}
}


@media (max-width: 576px) {
	.react-datepicker-popper {
		transform: translate(0, 191px) !important;
	}

	.react-datepicker__month-container {
		width: 100%;
	}
}


.arrowButton:hover {
	animation: pulse 0.8s ease-out;
	background-color: #2C2E2C;
}


@keyframes pulse {
	0% {
		box-shadow: 0 0 0 0 rgba(36, 38, 36, 0.5);
	}

	100% {
		box-shadow: 0 0 0 15px rgba(36, 38, 36, 0);
	}
}

/* Dropdown button */

.k-dropdown-button .dropdown-button {
	background-color: var(--k-primary) !important;
}

.k-dropdown-button .dropdown-menu.show {
	border-radius: 15px;
	margin-top: 5px;
	font-size: 0.9rem;
	padding: 10px;
	translate: (0px, 40px) !important;
}

.dropdown-menu.show {
	transform: translate(0px, 35px) !important;
}


.dropdown-menu {
	padding: 15px;
	border: 1px solid var(--k-border-lightgrey);
	border-radius: 24px;
	box-shadow: 0px 40px 32px -24px rgb(15 15 15 / 12%);
}


.k-dropdown-button .dropdown-item {
	border-radius: 10px;
	padding-top: 6px;
	padding-bottom: 6px;
}

.dropdown-item:hover,
.dropdown-item:focus {
	background-color: var(--k-ultra-lightgrey) !important;
	border-radius: 6px;
}