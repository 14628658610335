:root {
	/* Cards */
	--promotional_card_width: 306px;
	--promotional_card_height: 507px;
	--promotional_card_mobile_width: 240px;
	--promotional_card_mobile_height: 442px;

	--venue_card_width: 300px;
	--venue_card_height: 460px;
	--venue_card_mobile_width: 355px;
	--venue_card_mobile_height: 480px;

	--listing_card_list_width: 956px;
	--listing_card_list_height: 240px;

	--map_card_width: 430px;
	--map_card_height: 205px;

	--priority_color: #F96262
}

/* Promoted card */

.promoted_card {
	margin: 0 auto;
}

@media (max-width: 1200px) {
	.promoted-cards-wrapper {
		max-width: 990px;
	}
}

@media (max-width: 992px) {
	.promoted-cards-wrapper {
		max-width: 680px;
	}

	.promoted-cards-wrapper .scrolling-wrapper-flexbox .promoted-card-container>div:first-of-type {
		display: flex;
		justify-content: center;
	}

	.venue_card {
		background-color: white;
		border-radius: 24px;
		overflow: hidden;
		scroll-snap-align: start;
	}

	.venue_img_card_scale {
		width: 100% !important;
		height: 210px;
		object-fit: cover;
	}
}

@media (min-width: 768px) {
	.promoted_card {
		max-width: var(--promotional_card_width);
		border-radius: 24px;
		/* overflow: hidden; */
		scroll-snap-align: start;
	}

	.promoted_img_card_scale {
		border-radius: 24px;
		height: 352px;
		object-fit: cover;
		width: 100%;
	}

	.promotedcard_profile_image {
		width: 70px;
		height: 70px;
		position: absolute;
		z-index: 100;
		right: 10px;
		top: 10px;
	}

	.venuecard_profile_image {
		width: 75px;
		height: 75px;
		position: absolute;
		right: 10px;
		top: 10px;
		padding: 10px !important;
	}

	.location_text_wrapper {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		padding-bottom: 30px;
		padding-left: 30px;
	}
}

@media (max-width: 767px) {
	.promotedcard_profile_image {
		width: 70px;
		height: 70px;
		position: absolute;
		z-index: 100;
		right: 10px;
		top: 10px;
	}

	.promoted_img_card_scale {
		border-radius: 24px;
		height: 352px;
		object-fit: cover;
		width: 100%;
	}

	.selected_venue_img {
		width: 100%;
		height: 240px;
	}

	.venuecard_profile_image {
		width: 60px;
		height: 60px;
		position: absolute;
		right: 10px;
		top: 10px;
		padding: 10px !important;
	}

	.location_text_wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
	}

	.promoted_card {
		height: var(--promotional_card_mobile_height);
		border-radius: 24px;
		/* overflow: hidden; */
		scroll-snap-align: start;
	}
}


@media (max-width: 575px) {
	.promoted_card {
		max-width: 300px;
	}

	.promoted-card-container {
		max-width: 330px !important;
	}

	.selected_venue_img {
		height: 140px;
	}

}

@media (min-width: 1440px) {
	.promoted_img_card_scale {
		height: 440px;
	}
}

.descfont {
	font-size: 12px;
}

.descfont-medium {
	font-size: 13px;
}

.descfont_listing {
	font-size: var(--k-text-md);
	line-height: 1.429;
	min-height: 75px;
}

.desc_lineheight {
	line-height: 1.667;
}

.priserfra {
	color: #5E7175;
	font-weight: 600;
	font-size: 12px;
}

/* Map card */

.leaflet-popup-close-button {
	top: 8px !important;
	right: 8px !important;
}

.map_card {
	background-color: white;
	border-radius: 24px;
}

.leaflet-popup-content {
	margin: 0 !important;
	padding: 10px;
	width: 400px !important;
}

.leaflet-popup-content-wrapper {
	border-radius: 18px !important;
}

.map_card_img {
	width: 160px;
	height: 120px;
	object-fit: cover;
	border-radius: 10px;
}

.map-card-text {
	max-width: 402px;
}


.map_card_icon_image {
	width: 40px;
	height: 40px;
	margin-top: -70px;
	margin-left: -45px;
}

/* venue */
.venue_card {
	background-color: white;
	border-radius: 24px;
	overflow: hidden;
	scroll-snap-align: start;
	height: 440px;
	width: 100%;
}

.venue_card_border {
	border: 2px solid rgba(0, 0, 0, 0.08);
}

.venue_img_card_scale {
	width: 100%;
	min-width: 300px;
	height: 210px;
	object-fit: cover;
}

.venue_card_mobile {
	background-color: white;
	width: var(--venue_card_mobile_width);
	height: var(--venue_card_mobile_height);
	border-radius: 24px;
	overflow: hidden;
	scroll-snap-align: start;
}

.venuecard_organizer_icon {
	width: 70px;
	height: 70px;
	margin-top: -355px;
	margin-left: 242px;
}

.badge_venue {
	border-radius: var(--k-border-rounded);
	border-color: white;
	border-width: 1px;
	border-style: solid;
}

.gradient_bg {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgb(0, 0, 0);
	background: linear-gradient(0deg, rgba(35, 38, 47, 1) 0%, rgba(35, 38, 47, 0) 100%);
}

.gradient_bg_loc {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgb(0, 0, 0);
	background: linear-gradient(0deg, rgba(35, 38, 47, 1) 0%, rgba(35, 38, 47, 0) 35%);
}

/* Listing - List */
.listing_card_list {
	width: 100%;
	height: var(--listing_card_list_height);
	background-color: #fff;
}

.lcl_1 {
	flex: 1;
}

.lcl_2 {
	flex: 3;
}

.lcl_3 {
	flex: 1;
}

.k-btn-greybg {
	background-color: var(--k-input-lightblue);
	border-radius: var(--k-border-rounded);
	border: var(--k-border-2);
	border-color: var(--k-input-lightblue);
	font-size: var(--k-text-md);
}

.temp_icon_null {
	/* XX Delete afte icons work */
	height: 70px;
	width: 40px;
}

.shadow-hard {
	box-shadow: 1px 1px 30px 0px rgba(0, 0, 0, 0.12);
	-webkit-box-shadow: 1px 1px 30px 0px rgba(0, 0, 0, 0.12);
	-moz-box-shadow: 1px 1px 30px 0px rgba(0, 0, 0, 0.12);
}

.border-radius-12 {
	border-radius: 12px;
}

.border-radius-8 {
	border-radius: 8px;
}

/* Badges*/

.priority_badge {
	z-index: 99;
	position: absolute;
	margin-top: 11px;
	margin-left: 11px;
	border-radius: 20px;
	background-color: var(--priority_color);
	color: white;
}

.hotelofmonth_badge {
	position: absolute;
	margin-top: 11px;
	margin-left: 11px;
	border-radius: 20px;
	background-color: var(--priority_color);
	color: white;
}

.zoomable-image {
	transition: transform 0.3s ease;
}
  
.zoomable-image:hover {
	transform: scale(1.02);
}