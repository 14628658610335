.spinner-portal {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	z-index: 1000;
	position: fixed;
}
.non_overlay {
	top: 0px;
	right: 50%;
	transform: translate(50%);
}

.overlay {
	opacity: 0.8;
	background-color: var(--sibilus_white);
	width: 100vw;
	top: 0px;
}

.spinner {
	animation: spin 1.0s infinite linear;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	50% {
		transform: rotate(180deg);
	}

	100% {
		transform: rotate(360deg);
	}
}
